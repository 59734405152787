export const ACCOUNT_TYPE = {
  CORPORATE: "Corporate",
  ARTISAN: "Artisan",
  JOB_SEEKER: "Job Seeker",
  INSTANT_HIRE: "Instant Hire",
};

export const ROLE = {
  SUPER_ADMIN: "Super-admin",
  DEVELOPER: "developer",
};
